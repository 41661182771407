<template>
  <div class="modalPopup check_modal">
    <div class="modal_header">
      <h3 class="title">타이머 정보</h3>
      <button class="modal_close" @click="modalClose"></button>
    </div>
    <select-timer-sensor
      v-if="stepIndex == 0"
      :sensorList="sensorList"
      :stepIndex="stepIndex"
      @selectSensorInfo="selectSensorInfo = $event"
    ></select-timer-sensor>
    <select-timer-info
      v-show="stepIndex != 0"
      :selectSensorInfo="selectSensorInfo"
    ></select-timer-info>
    <div class="modal_footer">
      <button
        type="button"
        class="btn_prev"
        v-if="stepIndex > 0"
        @click="
          () => {
            if (stepIndex == 1) stepIndex = 0;
            else stepIndex--;
          }
        "
      >
        이전
      </button>
      <button
        class="btn_sub2"
        :disabled="stepIndex == 0 && selectSensorInfo == null"
        @click="stepIndex == 0 ? (stepIndex = 1) : modalClose()"
      >
        {{ stepIndex == 1 ? '닫기' : '다음' }}
      </button>
    </div>
  </div>
</template>

<script>
import SPINNER_MIXIN from '@/mixins/spinner';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import SelectTimerSensor from '@/layouts/components/23/SelectTimer';
import SelectTimerInfo from '@/layouts/components/23/TimerInfo';

export default {
  mixins: [SPINNER_MIXIN, FETCH_MIXIN, DRAG_MODAL_MIXIN],
  components: {
    SelectTimerSensor,
    SelectTimerInfo,
  },
  props: {
    sensorList: { type: Array, require: true },
    lot_id: {
      required: true,
    },
    product_id: {
      required: true,
    },
  },
  data() {
    return {
      stepIndex: 0,
      selectSensorInfo: null,
      checkSensor: null,

      sensor_list: null,
      interval: null,
    };
  },
  computed: {},
  methods: {
    modalClose() {
      if (this.stepIndex > 0) {
        this.UPDATE_COUNTER_LOG();
      }
      this.stepIndex = 0;
      this.$emit('onclose');
    },
    async UPDATE_COUNTER_LOG() {
      await this.$store
        .dispatch('FETCH_COUNTER_LOG_NOW')
        .then(data_list => {
          if (data_list.length < 1) {
            // this.openOneButtonModal('오류', '연동 종료 중 오류발생');
          } else {
            let temp = this.lodash
              .clonedeep(data_list)
              .filter(x => x.plc_sensor_id == this.selectSensorInfo.id);
            temp = temp.map(x => {
              return {
                id: x.id,
                value: x.value,
              };
            });
            this.UPDATE_WITH_PAYLOAD('UPDATE_COUNTER_LOG', { data_list: temp });
          }
        })
        .catch(error => {
          console.log('error', error);
          this.openOneButtonModal('오류', '연동 종료 중 오류발생');
        });
    },
  },
  async destroyed() {
    clearInterval(this.interval);
    await this.UPDATE_COUNTER_LOG();
  },
  created() {
    if (this.stepIndex != 0) {
      this.stepIndex = 0;
    }
  },
};
</script>

<style scoped></style>
