<template>
  <div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th></th>
              <th>타이머명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sensorList" :key="index">
              <td>
                <div class="input_radio">
                  <div>
                    <label
                      :for="`radio1_${index}`"
                      class="label"
                      :class="{ active: checkSensor.includes(index) }"
                      @click="selectSensor(index)"
                    ></label>
                    <input
                      :id="`radio1_${index}`"
                      type="radio"
                      name="search_opt"
                      :checked="checkSensor == index"
                    />
                  </div>
                </div>
              </td>
              <td>
                {{
                  `${item[0].plc_sensor_name.split(' ')[1]} ${
                    item[0].plc_sensor_name.split(' ')[2]
                  } ${item[0].plc_sensor_name.substring(16, 18)}`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    sensorList: { type: Array, require: true },
    stepIndex: { require: true },
  },
  data() {
    return {
      checkSensor: [],
    };
  },
  methods: {
    selectSensor(index) {
      const includeIndex = this.checkSensor.findIndex(x => x == index);
      if (includeIndex != -1) {
        this.checkSensor.splice(includeIndex, 1);
      } else {
        this.checkSensor.push(index);
      }
      const filterSensorList = this.lodash
        .clonedeep(this.sensorList)
        .filter((x, index) => this.checkSensor.includes(index));
      this.$emit('selectSensorInfo', filterSensorList);
    },
  },
  watch: {
    stepIndex(newValue) {
      if (newValue != 0) {
        this.checkSensor = null;
      }
    },
  },
};
</script>

<style scoped></style>
