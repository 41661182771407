<template>
  <div>
    <div class="modal_body timer_info_from">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>NO</th>
              <th>타이머명</th>
              <th>시 분 초</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selectSensorInfo" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                {{
                  `${item[0].plc_sensor_name.split(' ')[1]} ${
                    item[0].plc_sensor_name.split(' ')[2]
                  } ${item[0].plc_sensor_name.substring(16, 18)}`
                }}
              </td>
              <td v-if="item[0].plc_sensor_detail_type_detail.includes('hour')">
                {{
                  item[0].value +
                    '시 ' +
                    item[1].value +
                    '분 ' +
                    item[2].value +
                    '초'
                }}
              </td>
              <td v-else>
                {{ item[0].value + '분 ' + item[1].value + '초 ' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    selectSensorInfo: { require: true },
  },
  data() {
    return {
      checkSensor: null,
    };
  },
};
</script>

<style scoped></style>
